import React, { Component } from 'react';
import InputMask from 'react-input-mask';

class BannerV8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption1: false,
            selectedOption2: false,
        };
    }

    handleOptionChange = (option) => {
        this.setState((prevState) => ({
            [`selectedOption${option}`]: !prevState[`selectedOption${option}`],
        }));
    };

    handleClearSelections = () => {
        this.setState({
            selectedOption1: false,
            selectedOption2: false,
        });
    };

    render() {
        const publicUrl = process.env.PUBLIC_URL + '/';
        const { selectedOption1, selectedOption2 } = this.state;

        return (
            <div className="ltn__slider-area ltn__slider-6 mb-120---">
                <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
                    <div className="ltn__slide-item--- ltn__slide-item-9 section-bg-1 bg-image" data-bs-bg={publicUrl + "assets/img/slider/fundo.jpg"}>
                        <div className="ltn__slide-item-inner">
                            <div className="slide-item-info bg-overlay-white-90 text-center">
                                <div className="slide-item-info-inner ltn__slide-animation">
                                    <div id="mc_embed_signup">
                                        <form
                                            action="https://primeinsights.us12.list-manage.com/subscribe/post?u=3471c5d24a27c177a21b5cff8&amp;id=90b1f06c74&amp;f_id=008c4ce0f0"
                                            method="post"
                                            id="mc-embedded-subscribe-form"
                                            name="mc-embedded-subscribe-form"
                                            className="validate"
                                            target="_blank"
                                        >
                                            <div id="mc_embed_signup_scroll">
                                                <div className="mc-field-group">
                                                    <label htmlFor="mce-FNAME">Nome </label>
                                                    <input type="text" name="FNAME" className="text" id="mce-FNAME" style={{ height: '35px' }}/>
                                                </div>
                                                <div className="mc-field-group">
                                                    <label htmlFor="mce-EMAIL">Endereço de e-mail <span className="asterisk">*</span></label>
                                                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" style={{ height: '35px' }}/>
                                                </div>
                                                <div className="mc-field-group">
                                                    <label htmlFor="mce-PHONE">Telefone <span className="asterisk">*</span></label>
                                                    <InputMask
                                                        mask="(99) 9 9999-9999"
                                                        maskChar="_"
                                                        type="text"
                                                        name="PHONE"
                                                        className="REQ_CSS"
                                                        id="mce-PHONE"
                                                        style={{ height: '35px' }}
                                                    />
                                                </div>
                                                <div className="mc-field-group input-group">
    <div className="toggle-button">
        <input
            type="radio"
            id="option1"
            name="MMERGE3"
            value="Moradia"
            checked={selectedOption1}
            onChange={() => this.handleOptionChange(1)}
        />
        <label htmlFor="option1">Moradia</label>
    </div>
    <div className="toggle-button">
        <input
            type="radio"
            id="option2"
            name="MMERGE3"
            value="Investimento"
            checked={!selectedOption1 && selectedOption2} // Considerando que TODOS não está selecionado
            onChange={() => this.handleOptionChange(2)}
        />
        <label htmlFor="option2">Investimento</label>
    </div>
    <div className="toggle-button">
        <input
            type="radio"
            id="option3"
            name="MMERGE3"
            value="Todos"
            checked={selectedOption1 && selectedOption2}
            onChange={() => {
                this.handleOptionChange(1);
                this.handleOptionChange(2);
            }}
        />
        <label htmlFor="option3">Todos</label>
    </div>
</div>

                                                <div className="optionalParent">
                                                    <div className="clear foot">
                                                        <input
                                                            type="submit"
                                                            name="subscribe"
                                                            id="mc-embedded-subscribe"
                                                            className="button full-width"
                                                            value="INSCREVER-SE"
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BannerV8;

































